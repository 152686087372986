<template>
  <div>
    <div v-if="form.companyId" class="card invoice-details">
      <div
        class="card-header d-flex align-items-center justify-content-between"
      >
        <h2>{{ $t("Receiver") }}</h2>
      </div>
      <div class="card-body px-3">
        <div class="row align-items-start">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ form.companyId.companyName }}</h4>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ form.companyId.addressLine1 }}</h4>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ form.companyId.zipCode }} {{ form.companyId.city }}</h4>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ form.companyId.country }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="form.senderId" class="card invoice-details">
      <div
        class="card-header d-flex align-items-center justify-content-between"
      >
        <h2>{{ $t("Sender") }}</h2>
      </div>
      <div class="card-body px-3">
        <div class="row align-items-start">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ form.senderId.companyName }}</h4>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ form.senderId.addressLine1 }}</h4>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ form.senderId.zipCode }} {{ form.senderId.city }}</h4>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ form.senderId.country }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card invoice-details">
      <div
        class="card-header d-flex align-items-center justify-content-between"
      >
        <h2>{{ form.status }}</h2>
        <h2>{{ $dateFormatter(form.startDate, $i18n.locale) }}</h2>
      </div>
      <div class="card-body px-3">
        <div class="row mt-2 align-items-start">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ $t("Customer Nr.") }}</h4>
              <p v-if="form.companyId.companyNumber">
                {{ form.companyId.companyNumber }}
              </p>
              <p v-else>-</p>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ $t("Invoice Nr.") }}</h4>
              <p v-if="form.invoiceNumber">
                {{ form.invoiceNumber }}
              </p>
              <p v-else>-</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div v-if="!isCreated" class="d-flex align-items-center justify-content-between">
              <h4>{{ $t("Created By") }}:</h4>
              <p>{{ user.first_name }} {{ user.last_name }}</p>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ $t("Due Date") }}:</h4>
              <p>{{ $dateFormatter(form.dueDate, $i18n.locale) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
    isCreated: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object
    },
  },
};
</script>
